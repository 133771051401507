import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Intersect from '@/assets/Intersect.png';
// import leftaward from '@/assets/leftaward.png';
import award from '@/assets/award.png';
// import rightaward from '@/assets/rightaward.png';
import modalbtn from '@/assets/modalbtn.png';
import cconse from '@/assets/cconse.png';
import Rectangle from '@/assets/Rectangle.png';
import './Modal.css';

const Modal = ({ show, prizeIcon, value, content, onClick, btnName }) => {
  const [isVisible, setIsVisible] = useState(show);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
      setIsClosing(false);
    } else {
      setIsClosing(true);
      setTimeout(() => setIsVisible(false), 300); // 等待动画结束
    }
  }, [show]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className={`w-72 shadow-lg z-50 rounded-3xl  modal-body relative ${isClosing ? 'scale-out' : ''}`}
      >
        {!btnName && (
          <div className="absolute top-[-46%] left-0 z-[99]">
            <div className="flex justify-center items-center relative">
              <img src={award} alt="" className="w-full h-full" />
            </div>
          </div>
        )}
        <div className="flex justify-center">
          <div
            className="w-full h-[62px] flex justify-center items-center"
            style={{
              backgroundImage: `url(${Intersect})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
            }}
          >
            {btnName && (
              <p className="text-xl text-white font-semibold">NOTE</p>
            )}
          </div>
        </div>
        <div className="p-5 flex flex-col items-center">
          <p className="text-center text-sm">{content}</p>
          {prizeIcon && (
            <div
              className="w-[215px] h-[45px] text-white text-lg flex items-center justify-center"
              style={{
                backgroundImage: `url(${Rectangle})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
              }}
            >
              Successfully received
            </div>
          )}

          {!btnName && (
            <div
              className="flex items-center border-b border-white text-[#7c58d1] text-xl"
              style={{
                textShadow: '1px 0px 1px 0px rgba(0, 0, 0, 0.78)',
              }}
            >
              <div>+{value}</div>
              <img src={prizeIcon} alt="" className="w-8 h-8 ml-2" />
            </div>
          )}
          {/* <div className="absolute top-[5.5vw] right-[13vw] text-white text-2xl">
            *{value}
          </div>
          <img
            className="absolute top-[1vw] left-[2.5vw]"
            src={prizeIcon}
            alt=""
          /> */}
          {btnName && (
            <div
              className="w-64 h-14 mt-4 px-4 py-2 text-white rounded flex justify-center text-xl"
              onClick={onClick}
              style={{
                backgroundImage: `url(${modalbtn})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                textShadow: '0px 3px 5px #333333',
              }}
            >
              {btnName}
            </div>
          )}
        </div>
        {!btnName && (
          <div className="w-full h-[16px]  absolute bottom-[-10vw] left-0 flex items-center justify-center">
            <img src={cconse} alt="" className="w-8	h-8" onClick={onClick} />
          </div>
        )}
      </div>
      <div className="fixed inset-0 bg-black opacity-50"></div>
    </div>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  prizeIcon: PropTypes.string,
  value: PropTypes.number,
  content: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  btnName: PropTypes.string.isRequired,
};

export default Modal;
