import { useState, useEffect } from 'react';
import reborder from '@/assets/reborder.png';
import iconStore from '@/utils/hooks/iconStore';

const usePrizeTransformData = (rawData) => {
  const [transformedData, setTransformedData] = useState([]);
  const [currencyListLoaded, setCurrencyListLoaded] = useState(false);

  const reImg = {
    src: reborder,
    width: '14vw',
    top: '2.5vw',
  };

  useEffect(() => {
    const checkCurrencyList = () => {
      if (iconStore.currencyList && iconStore.currencyList.length > 0) {
        setCurrencyListLoaded(true);
      } else {
        console.warn('iconStore.currencyList is not loaded yet');
      }
    };
    checkCurrencyList();
    const intervalId = setInterval(checkCurrencyList, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (rawData && currencyListLoaded) {
      const prizeTransformData = (rawData) => {
        return rawData.wheels.map((wheel) => {
          const relatedPrizes = rawData.prizes;
          const transformedPrizes = relatedPrizes.map((prize, index) => {
            const matchedCurrency = iconStore.currencyList.find(
              (currency) => currency.id === prize.currency_id,
            );
            const currencyImg = matchedCurrency
              ? {
                  src: `${import.meta.env.VITE_API_TARGET}${matchedCurrency.icon}`,
                  width: '10vw',
                  top: '3vw',
                  left: '-0.3vw',
                }
              : null;

            return {
              fonts: [
                {
                  text: prize.value,
                  top: '15vw',
                  left: '-0.5vw',
                  fontSize: '10px',
                  fontColor: 'white',
                },
              ],
              imgs: [reImg, currencyImg].filter(Boolean),
              value: prize.value,
              id: prize.id,
              priority: relatedPrizes.length - index,
              rate: 1 / (index + 1),
              currency_id: prize.currency_id,
              order: prize.order + 1,
              create_at: prize.create_at,
            };
          });

          return {
            cost_type: wheel.cost_type,
            id: wheel.id,
            cost_value: wheel.cost_value,
            enable: wheel.enable,
            title: `${wheel.cost_type} for game.`,
            max_prize: relatedPrizes.length,
            prizes: transformedPrizes,
            update_at: wheel.create_at,
            create_at: wheel.create_at,
          };
        });
      };

      setTransformedData(prizeTransformData(rawData));
    }
  }, [rawData, currencyListLoaded]);

  return transformedData;
};

export default usePrizeTransformData;
