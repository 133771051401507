import axios from 'axios';
import WebApp from '@twa-dev/sdk';
import loadingStore from '@/utils/hooks/loadingStore';

const apiClient = axios.create({
  timeout: 1000 * 30,
  baseURL: import.meta.env.VITE_API_URL,
  // import.meta.env.VITE_API_URL
  // http://api.bitplay.fun
  headers: {
    'Content-Type': 'application/json',
  },
});

let activeRequests = 0;
let isLoading = false;
let debounceTimeout;

const setLoading = (loading) => {
  if (loading !== isLoading) {
    isLoading = loading;
    loadingStore.setLoading(loading);
  }
};

const debounceSetLoading = (loading, delay = 300) => {
  clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(() => {
    setLoading(loading);
  }, delay);
};

apiClient.interceptors.request.use(
  (config) => {
    if (!config.skipLoading) {
      if (activeRequests === 0) {
        setLoading(true);
      }
      activeRequests += 1;
    }
    let myToken = '';
    if (import.meta.env.MODE === 'development') {
      myToken = import.meta.env.VITE_APP_HEADER_DATA;
    } else {
      myToken = WebApp && WebApp.initData;
    }
    config.headers.Authorization = myToken;
    return config;
  },
  (error) => {
    if (!error.config.skipLoading) {
      activeRequests -= 1;
      if (activeRequests === 0) {
        debounceSetLoading(false);
      }
    }
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response) => {
    if (!response.config.skipLoading) {
      activeRequests -= 1;
      if (activeRequests === 0) {
        debounceSetLoading(false);
      }
    }
    return response.data;
  },
  (error) => {
    if (!error.config.skipLoading) {
      activeRequests -= 1;
      if (activeRequests === 0) {
        debounceSetLoading(false);
      }
    }
    return Promise.reject(error);
  },
);

export default apiClient;
