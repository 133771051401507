import { makeAutoObservable } from 'mobx';
import { getCreateUser } from '@/api/apiMethods.js';

class UserStore {
  user = null;
  point = 0;
  ton = 0;
  credit = 0;
  constructor() {
    makeAutoObservable(this);
  }

  async fetchUserInfo() {
    try {
      const createUser = await getCreateUser();
      console.log('createUser', createUser);
      this.point = createUser.point;
      this.ton = createUser.ton.toFixed(2);
      this.credit = createUser.credit;
      console.log('createUser', createUser);
      console.log('response', createUser);
      this.user = createUser;
    } catch (error) {
      console.log(error);
    }
  }
  addPoint(val) {
    this.point += val;
  }
  addTon(val) {
    console.log('11this.ton', this.ton);
    this.ton = (parseFloat(this.ton) + parseFloat(val)).toFixed(2);
    console.log('this.ton', this.ton);
    console.log('val', val);
  }
  addCredit(val) {
    this.credit += val;
  }
  subtractPoint(val) {
    this.point -= val;
  }

  subtractTon(val) {
    this.ton = (parseFloat(this.ton) - parseFloat(val)).toFixed(2);
  }

  subtractCredit(val) {
    this.credit -= val;
  }
}

const userStore = new UserStore();
export default userStore;
