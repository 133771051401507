import { makeAutoObservable } from 'mobx';
import { getCurrencyList } from '@/api/apiMethods.js';

class IconStore {
  currencyList = null;
  pointIconUrl = null;
  creditIconUrl = null;
  tonIconUrl = null;
  constructor() {
    makeAutoObservable(this);
  }

  async getCurrencyList() {
    try {
      const getList = await getCurrencyList();
      console.log('getList', getList);
      this.currencyList = getList;
      this.pointIconUrl = `${getList[0].icon}`;
      this.tonIconUrl = `${getList[1].icon}`;
      this.creditIconUrl = `${getList[2].icon}`;
    } catch (error) {
      console.log(error);
    }
  }
}

const iconStore = new IconStore();
export default iconStore;
