import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gamesStore from '@/utils/hooks/gamesStore';
import WebApp from '@twa-dev/sdk';
import { Empty } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import hot from '@/assets/hot.gif';
import noimg from '@/assets/noimg.png';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Games = () => {
  const [GameItems, setGameItems] = useState([]);

  const toUrl = (url) => {
    if (url.includes('t.me')) {
      WebApp.openTelegramLink(url);
    } else {
      WebApp.openLink(url, { try_instant_view: true });
    }
  };

  const fetchGameData = async () => {
    try {
      await gamesStore.fetchGameInfo();
      const games_list = gamesStore.gameList;
      setGameItems(games_list);
      preloadImages(games_list);
    } catch (error) {
      console.log(error);
    }
  };

  const preloadImages = (games_list) => {
    games_list.forEach((item) => {
      const imageUrl =
        item.image_url && item.image_url.includes('https')
          ? item.image_url
          : `${item.image_url ? item.image_url : ''}`;

      if (imageUrl) {
        const img = new Image();
        img.src = imageUrl;
      }
    });
  };

  useEffect(() => {
    fetchGameData();
  }, []);

  return (
    <div>
      <div>
        <main className="w-full h-full p-3">
          <div className="flex justify-between mb-2">
            <p className="text-3xl text-white font-bold">
              Games{' '}
              <span className="text-xl">
                ({GameItems ? GameItems.length : 0})
              </span>
            </p>
          </div>
          {Array.isArray(GameItems) && GameItems.length > 0 ? (
            GameItems.map((item, index) => (
              <div
                key={index}
                className="w-full h-full flex bg-[#282b47] text-white mt-4 p-4 rounded-[24px] shadow-[rgba(0,0,28,.08)_0_2px_28px,rgba(0,0,28,.16)_0_0_1px] transition-transform duration-200 ease-in-out transform hover:bg-[#2631fb] hover:scale-105 active:scale-95"
                onClick={() => toUrl(item.link)}
              >
                <div>
                  <div className="w-full flex items-center">
                    <div className="rounded w-16 h-16 flex-shrink-0">
                      <LazyLoadImage
                        src={
                          item.image_url && item.image_url.includes('https')
                            ? item.image_url
                            : `${item.image_url ? item.image_url : ' '}`
                        }
                        alt="icon"
                        className="object-cover rounded-lg h-full w-full"
                        effect="blur"
                        placeholderSrc={noimg}
                      />
                    </div>
                    <div className="w-full ml-3">
                      <p className="text-xl font-medium flex items-center">
                        {item.name}
                        {item.source === 'internal' && (
                          <img src={hot} className="w-10 h-full ml-2" alt="" />
                        )}
                      </p>
                      <p className="text-xs text-[#8e8e93] with-dot w-[206px] break-words line-clamp-2 mt-1">
                        {item.description ? item.description : '-'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <Empty />
          )}
        </main>
      </div>
      <style>{`
        .with-dot::before {
          width:4px;
          height:4px;
          content: '•';
          color: blue;
          margin-right: 4px;
          font-size: 1.2em;
          vertical-align: middle;
        }
      `}</style>
    </div>
  );
};

Games.propTypes = {
  setShowTabbar: PropTypes.func.isRequired,
};

export default Games;
