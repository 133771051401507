import PropTypes from 'prop-types';

const PageNavigator = ({ page, handlePageChange, children }) => {
  const navigateToPage = () => {
    handlePageChange(page);
  };

  return (
    <div onClick={navigateToPage} style={{ cursor: 'pointer' }}>
      {children}
    </div>
  );
};

PageNavigator.propTypes = {
  page: PropTypes.string.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default PageNavigator;
