import { makeAutoObservable } from 'mobx';
import { getGameList } from '@/api/apiMethods.js';

class UserStore {
  gameList = null;
  gameBanner = null;
  constructor() {
    makeAutoObservable(this);
  }

  async fetchGameInfo(params) {
    try {
      const getList = await getGameList(params);
      this.gameList = getList?.games_list || [];
    } catch (error) {
      console.log(error);
    }
  }
}

const userStore = new UserStore();
export default userStore;
