import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomProgress from '@/components/CustomProgress/CustomProgress';
import wton from '@/assets/ton.png';
import './ProModal.css';

// eslint-disable-next-line react/prop-types
const Modal = ({ show, title, ton, onClick, pernum, mixNum }) => {
  const [isVisible, setIsVisible] = useState(show);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    console.log('mixAmount', mixNum);
    if (show) {
      setIsVisible(true);
      setIsClosing(false);
    } else {
      setIsClosing(true);
      setTimeout(() => setIsVisible(false), 300); // 等待动画结束
    }
  }, [show]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      <div
        className={`w-72 h-72 shadow-lg z-10 rounded-3xl promodal-body ${isClosing ? 'scale-out' : ''}`}
      >
        <div className="w-full h-12 flex justify-center items-center text-white text-xl">
          {title}
        </div>
        <div className="w-full text-white text-xl p-3 ">
          <CustomProgress percent={pernum} ton={ton} mixNum={mixNum} />
        </div>
        <div className="w-full  mt-4 p-3 text-white flex flex-col justify-center  items-center text-xs">
          <p className="flex">
            you have: <img src={wton} className="h-4" alt="TON" /> {ton}
          </p>
          <p className="flex">
            withdrawable over <img src={wton} className="h-4" alt="TON" />{' '}
            {mixNum}
          </p>
        </div>
        <div className="p-3">
          <div
            className="w-full h-11 bg-[#2631fb] rounded-full flex items-center justify-center text-white text-base"
            onClick={onClick}
          >
            Cancel
          </div>
        </div>
      </div>
      <div className="fixed inset-0 bg-black opacity-50"></div>
    </div>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  value: PropTypes.number,
  title: PropTypes.string.isRequired,
  ton: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  pernum: PropTypes.number.isRequired,
};

export default Modal;
