import { Badge } from 'antd';
import PropTypes from 'prop-types';
import homeImage from '@/assets/home.png';
import gameImage from '@/assets/game.png';
import aiImage from '@/assets/ai.png';
import accountImage from '@/assets/account.png';
import spinImage from '@/assets/spin.png';
import homeActive from '@/assets/homeActive.png';
import gameActive from '@/assets/gameActive.png';
import accActive from '@/assets/accActive.png';
import aiActive from '@/assets/aiActive.png';
import spinActive from '@/assets/spinActive.png';
import './TabBar.css';
const MenuItem = ({
  src,
  activeSrc,
  label,
  isActive,
  handlePageChange,
  page,
  showdot,
}) => {
  return (
    <button
      onClick={() => handlePageChange(page)}
      className={`py-2 px-4 rounded cursor-pointer flex flex-col items-center text-xs
        ${isActive ? 'text-white active' : 'text-[#c3c3c3] inactive'}`}
    >
      <Badge dot={showdot}>
        <div className={`icon-background relative ${isActive ? 'active' : ''}`}>
          {isActive && (
            <div className="w-12 h-6 bg-[#13142e] absolute top-[2px] left-[-9px] rounded-full"></div>
          )}
          <img
            src={isActive ? activeSrc : src}
            alt={label}
            width={28}
            height={28}
          />
        </div>
      </Badge>
      {label}
    </button>
  );
};

MenuItem.propTypes = {
  src: PropTypes.string.isRequired,
  activeSrc: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  showdot: PropTypes.bool.isRequired,
};
const TabBar = ({ currentPage, handlePageChange }) => {
  const menuItems = [
    {
      page: 'home',
      src: homeImage,
      activeSrc: homeActive,
      label: 'Home',
      showdot: false,
    },
    {
      page: 'games',
      src: gameImage,
      activeSrc: gameActive,
      label: 'Games',
      showdot: false,
    },
    {
      page: 'ai',
      src: aiImage,
      activeSrc: aiActive,
      label: 'AI',
      showdot: false,
    },
    {
      page: 'lucky',
      src: spinImage,
      activeSrc: spinActive,
      label: 'Spin',
      showdot: false,
    },
    {
      page: 'account',
      src: accountImage,
      activeSrc: accActive,
      label: 'Account',
      showdot: false,
    },
  ];

  return (
    <div
      className="fixed bottom-0 left-0 right-0 bg-[#1d1e38]  py-1 px-1 flex justify-around items-center z-[99]"
      style={{ paddingBottom: 'env(safe-area-inset-bottom)' }}
    >
      {menuItems.map((item) => (
        <MenuItem
          key={item.page}
          src={item.src}
          label={item.label}
          activeSrc={item.activeSrc}
          isActive={currentPage === item.page}
          handlePageChange={handlePageChange}
          page={item.page}
          showdot={item.showdot}
        />
      ))}
    </div>
  );
};
TabBar.propTypes = {
  currentPage: PropTypes.string.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};
export default TabBar;
