import { taskList } from '@/api/apiMethods.js';
export async function getTaskList() {
  try {
    const res = await taskList();
    console.log(res, '1232131232113231');
    const finishedItems = res.filter((item) => item.status === 'finish');
    const unfinishedItems = res
      .filter((item) => item.status === 'unfinished')
      .sort((a, b) => a.sort - b.sort);
    console.log('unfinishedItems', unfinishedItems);
    const waitGetAwardItem = res.filter(
      (item) => item.status === 'wait_get_award',
    );
    return { unfinishedItems, finishedItems, waitGetAwardItem };
  } catch (error) {
    console.log(error);
    throw error;
  }
}
