import { eventConfirm } from '@/api/apiMethods.js';
// import { message } from 'antd';
export const toClaimFn = async (matchedData) => {
  console.log(matchedData);
  try {
    await eventConfirm({ oid: matchedData[0].id });
    // message.success('Claim Successfully');
  } catch (error) {
    console.log(error);
  }
};
