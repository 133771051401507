import aidata from '@/assets/aidata.gif';
export default function Aipage() {
  return (
    <div>
      <div>
        <main
          className="w-full h-[100vh] flex justify-center items-end"
          style={{
            backgroundImage: `url(${aidata})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            transition: 'opacity 1s ease-out',
          }}
        >
          <p className="text-3xl	text-white font-bold h-36">Coming Soon...</p>
        </main>
      </div>
    </div>
  );
}
