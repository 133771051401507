// src/api/apiMethods.js
import apiClient from './apiClient';
import endpoints from './endpoints';

export const getCreateUser = (data, params, headers) =>
  apiClient.post(endpoints.getCreateUser, data, { params, headers });

export const getUserInfo = (params, headers) =>
  apiClient.get(endpoints.getUserInfo, {
    params,
    headers,
  });

export const taskList = (params, headers) =>
  apiClient.get(endpoints.taskList, {
    params,
    headers,
  });

export const getGameList = (params, headers) =>
  apiClient.get(endpoints.getGameList, {
    params,
    headers,
  });

export const eventTask = (params, headers) =>
  apiClient.get(endpoints.eventTask, {
    params,
    headers,
  });

export function createTask(params) {
  const obj = { ...params };
  return apiClient.post(endpoints.createTask, JSON.stringify(obj), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export const eventConfirm = (oid, params, headers) =>
  apiClient.post(`${endpoints.eventConfirm}`, oid, { params, headers });

export const userInvite = (params, headers) =>
  apiClient.get(endpoints.getInvite, {
    params,
    headers,
  });

export const getInviteList = (params, headers) =>
  apiClient.get(endpoints.getInviteList, {
    params,
    headers,
  });

export const searchGameList = (params, headers) =>
  apiClient.get(endpoints.searchGameList, {
    params,
    headers,
  });

export const getWheelList = (params, headers) =>
  apiClient.get(endpoints.getWheelList, {
    params,
    headers,
  });

export const getCurrencyList = (params, headers) =>
  apiClient.get(endpoints.getCurrencyList, {
    params,
    headers,
  });

export const postCreateSpin = (wid, params, headers) =>
  apiClient.post(`${endpoints.postCreateSpin}`, wid, {
    params,
    headers,
    skipLoading: true,
  });

export const postConfirmSpin = (spin_id, params, headers) =>
  apiClient.post(`${endpoints.postConfirmSpin}`, spin_id, {
    params,
    headers,
    skipLoading: true,
  });

export const postAddress = (address, params, headers) =>
  apiClient.post(`${endpoints.postAddress}`, address, {
    params,
    headers,
    skipLoading: true,
  });

export const postTonCreate = (ton, params, headers) =>
  apiClient.post(`${endpoints.postTonCreate}`, ton, {
    params,
    headers,
    skipLoading: true,
  });

export const getSettingList = (params, headers) =>
  apiClient.get(endpoints.getSettingList, {
    params,
    headers,
    skipLoading: true,
  });
