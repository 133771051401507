import { useState, useEffect } from 'react';
import loadingStore from '@/utils/hooks/loadingStore';
import starpage from '@/assets/starpage.png';
import profile from '@/assets/profile.png';
import './Loading.css';

// eslint-disable-next-line react/prop-types
const ProgressBar = ({ setShowSplash }) => {
  const [progress, setProgress] = useState(0);
  const [hasCompleted, setHasCompleted] = useState(false);

  const incrementProgress = () => {
    setProgress((prev) => {
      if (prev < 90) {
        setTimeout(incrementProgress, 16);
        return prev + 1;
      }
      return prev;
    });
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    if (loadingStore.loading && !hasCompleted) {
      incrementProgress();
    } else if (!loadingStore.loading && !hasCompleted) {
      setTimeout(() => {
        setProgress(100);
        setHasCompleted(true);
      }, 2000);
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [loadingStore.loading, hasCompleted]);

  useEffect(() => {
    if (progress >= 100) {
      setTimeout(() => {
        setShowSplash(false);
      }, 1000);
    }
  }, [progress, setShowSplash]);

  return (
    <div className="relative">
      <div
        className="absolute inset-0 flex items-center justify-center flex-col bg-[#141645] z-[99999] min-h-screen overflow-hidden"
        style={{
          backgroundImage: `url(${starpage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          transition: 'opacity 1s ease-out',
        }}
      >
        <div className="w-full  absolute bottom-[45vw] left-0 h-24  text-white text-4xl	flex flex-col items-center justify-center">
          <p>
            <span className="Al_text mr-6">Al-driven</span>
            <span className="text-5xl web_text">Web3</span>{' '}
          </p>
          <p>Casual Games Hub</p>
        </div>
        <div
          className="flex flex-col items-center justify-center w-full absolute bottom-[20vw] left-0 h-24"
          style={{
            background: 'rgba(0, 0, 0, 0.6)',
            zIndex: 1001,
            transition: 'opacity 1s ease-out',
          }}
        >
          <div className="w-[80%] h-7 flex items-center justify-center p-2 border border-[#bbbac4] mt-6">
            <div
              style={{
                width: '100%',
                height: '12px',
                backgroundColor: '#eaeaea',
                overflow: 'visible',
                position: 'relative',
              }}
            >
              <div
                style={{
                  height: '100%',
                  width: `${progress}%`,
                  background:
                    'repeating-linear-gradient(73deg, #2631fb, #2631fb 10px, #ffffff 10px, #ffffff 20px)',
                  backgroundSize: '20px 20px',
                  transition: 'width 0.1s ease-out',
                }}
              />

              <div
                style={{
                  position: 'absolute',
                  top: '-13vw',
                  left: `calc(${progress}% - 8vw)`,
                  transition: 'transform 0.1s ease-out',
                  zIndex: 1002,
                }}
              >
                <img
                  src={profile}
                  alt="icon"
                  className="w-9 h-9"
                  style={{
                    objectFit: 'contain',
                    width: '36px',
                    height: '36px',
                    animation:
                      progress < 100 ? `flip 1s ease-in-out infinite` : 'none',
                    opacity: progress < 100 ? 1 : 0,
                    transition: 'opacity 1s ease-out',
                  }}
                />
              </div>
            </div>
          </div>
          <div className="h-[19px] bg-[#1c24ba] text-white flex items-center justify-center p-1">
            Earn, Cocreate and Mine
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
