import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
          <div className="text-center bg-white p-10 rounded-lg shadow-lg">
            <h1
              className="text-4xl font-bold text-red-500 mb-5"
              style={{ animation: 'fadeIn 1s ease-in-out' }}
            >
              Oops! Something went wrong
            </h1>
            <p
              className="text-lg text-gray-600 mb-10"
              style={{ animation: 'fadeIn 1.5s ease-in-out' }}
            >
              We&apos;re working to fix the problem. Please try again later.
            </p>
            <div className="flex justify-center space-x-4">
              <div
                className="w-10 h-10 bg-red-500 rounded-full"
                style={{ animation: 'spin 2s linear infinite' }}
              ></div>
              <div
                className="w-10 h-10 bg-red-500 rounded-full"
                style={{ animation: 'spin 3s linear infinite' }}
              ></div>
              <div
                className="w-10 h-10 bg-red-500 rounded-full"
                style={{ animation: 'spin 4s linear infinite' }}
              ></div>
            </div>
          </div>
          <style>{`
            @keyframes spin {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }

            @keyframes fadeIn {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
          `}</style>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
