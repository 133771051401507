import { useState, useEffect } from 'react';
import userStore from '@/utils/hooks/userStore';
import {
  useTonAddress,
  useTonWallet,
  useTonConnectModal,
} from '@tonconnect/ui-react';
import {
  getSettingList,
  postAddress,
  postTonCreate,
} from '@/api/apiMethods.js';
import { shortenedString } from '@/utils/shortenedString';
import planeicon from '@/assets/planeicon.png';
import ton from '@/assets/ton.png';
import start from '@/assets/start.png';
import card from '@/assets/card.png';
import docbg from '@/assets/docbg.png';
import close from '@/assets/close.png';
import wton from '@/assets/ton.png';
import avatar0 from '@/assets/avatar/0.jpg';
import avatar1 from '@/assets/avatar/1.jpg';
import avatar2 from '@/assets/avatar/2.jpg';
import avatar3 from '@/assets/avatar/3.jpg';
import avatar4 from '@/assets/avatar/4.jpg';
import avatar5 from '@/assets/avatar/5.jpg';
import { Drawer, Input, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ProModal from './components/ProModal/ProModal';
import './account.css';

export default function Account() {
  const [userInfo, setUserInfo] = useState({ first_name: '-' });
  const [walletAddress, setWalletAddress] = useState('');
  const userFriendlyAddress = useTonAddress();
  const wallet = useTonWallet();
  const { open } = useTonConnectModal();
  const [isDrawerOpen, setIsDrawerOpen] = useState([false, false]);
  const [isLastDrawerOpen, setIsLastDrawerOpen] = useState([false, false]);
  const [withdrawValue, setWithdrawValue] = useState(0);
  const [error, setError] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [mixAmount, setMixAmount] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [WithIsLoading, setWithIsLoading] = useState(false);
  const [DrawerHeight, setDrawerHeight] = useState('auto');

  const avatarIndex = userInfo.profile_photo;
  const avatars = [avatar0, avatar1, avatar2, avatar3, avatar4, avatar5];
  const avatarPath = avatars[avatarIndex];
  const handleWithdrawValueChange = (e) => {
    setError('');
    let { value } = e.target;
    if (value === '') {
      setWithdrawValue('');
    } else if (/^\d*\.?\d*$/.test(value)) {
      value = value.replace(/^(\d*\.\d{2})\d+$/, '$1');
      let numericValue = parseFloat(value);
      // numericValue = numericValue === 0 ? 1 : numericValue;
      console.log('numericValue', numericValue);
      console.log('withdrawValue', withdrawValue);
      setWithdrawValue(
        numericValue > userStore.ton
          ? Math.min(maxAmount, userStore.ton)
          : value,
      );
    }
  };

  const toggleDrawer = (index, value) => {
    setError('');
    setWithdrawValue('');
    setDrawerHeight('auto');
    const newOpen = [...isDrawerOpen];
    newOpen[index] = value;
    setIsDrawerOpen(newOpen);
  };

  const toggleLastDrawer = (index, value) => {
    const newOpenLast = [...isLastDrawerOpen];
    newOpenLast[index] = value;
    setIsLastDrawerOpen(newOpenLast);
  };

  const withdrawAll = () => {
    setWithdrawValue(Math.min(maxAmount, userStore.ton));
    setError('');
  };

  const handleWithdrawClick = async () => {
    setWithIsLoading(true);
    const settingInfo = await getSettingList();
    const maxAmountSetting = settingInfo.find(
      (item) => item.key === 'cashout_max_amount',
    );
    const minAmountSetting = settingInfo.find(
      (item) => item.key === 'cashout_min_amount',
    );
    const serviceChargeSetting = settingInfo.find(
      (item) => item.key === 'cashout_service_charge',
    );

    const maxAmount = maxAmountSetting ? maxAmountSetting.value : 0;
    const minAmount = minAmountSetting ? minAmountSetting.value : 0;
    const serviceCharge = serviceChargeSetting ? serviceChargeSetting.value : 0;
    setMixAmount(minAmount);
    setMaxAmount(maxAmount);
    setServiceCharge(serviceCharge);

    const userAmount = Number(userStore.ton) + Number(serviceCharge);
    const percentageValue = Math.floor((userAmount / Number(minAmount)) * 100);
    setPercentage(Number(String(percentageValue).slice(0, 2)));

    console.log('userStore.ton:', userStore.ton);
    console.log('Number(userStore.ton):', Number(userStore.ton));
    console.log('minAmount:', minAmount);

    if (minAmount > 0) {
      if (Number(userStore.ton) - serviceCharge >= minAmount) {
        toggleDrawer(1, true);
        setWithIsLoading(false);
      } else {
        setShowModal(true);
        setWithIsLoading(false);
      }
    } else {
      setShowModal(true);
      setWithIsLoading(false);
    }
  };

  const handleModalClick = () => {
    setShowModal(false);
  };

  const handleNextStep = () => {
    if (withdrawValue == 0) {
      setError('Please enter the withdrawal amount!');
      return;
    }
    if (withdrawValue <= serviceCharge) {
      setError(
        'After deducting the handling fee, the amount is insufficient, please recalculate',
      );
      return;
    }
    if (parseFloat(withdrawValue) <= parseFloat(mixAmount)) {
      console.log(mixAmount, serviceCharge);
      setError(
        `The minimum withdrawal amount is ${parseFloat(mixAmount) + parseFloat(serviceCharge)}, please re-enter`,
      );
      return;
    }
    if (withdrawValue) {
      toggleDrawer(1, false);
      toggleLastDrawer(1, true);
      console.log(Number(withdrawValue), Number(serviceCharge));
      const result = Number(withdrawValue) - Number(serviceCharge);
      console.log(result.toFixed(2));
      setWithdrawValue(parseFloat(result.toFixed(2)));
    } else {
      setError('Please enter the withdrawal amount!');
    }
  };

  const handleWithdraw = async () => {
    setIsLoading(true);
    try {
      console.log('withdraw');
      const TonCreate = await postTonCreate({ ton: Number(withdrawValue) });
      console.log(TonCreate, 'TonCreateTonCreate');
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      toggleLastDrawer(1, false);
      message.success('Withdraw successfully');
      userStore.subtractTon(Number(withdrawValue) + Number(serviceCharge));
    }
  };

  const handlePostAddress = async () => {
    try {
      const response = await postAddress({ address: walletAddress });
      console.log(response);
    } catch (error) {
      const errorMessage =
        error.response?.data?.['error: '] || 'An error occurred';
      console.log(errorMessage);
      localStorage.setItem('errorMessage', errorMessage);
    }
  };

  const handleInputFocus = () => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    console.log(isIOS, 'isIOS');
    if (isIOS) {
      setDrawerHeight('80vh');
    }
  };
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        setUserInfo(userStore.user || { first_name: '-' });
      } catch (error) {
        console.error(error);
      }
      setWalletAddress(userFriendlyAddress);
    };

    fetchUserInfo();
  }, [userFriendlyAddress]);

  useEffect(() => {
    setWalletAddress(wallet ? userFriendlyAddress : '');
  }, [wallet, userFriendlyAddress]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        setUserInfo(userStore.user || { first_name: '-' });
        const storedErrorMessage = localStorage.getItem('errorMessage');
        if (storedErrorMessage === 'wallet address is same.') {
          console.log('wallet address is same.');
        } else {
          await handlePostAddress();
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserInfo();
  }, [walletAddress]);

  return (
    <div>
      <ProModal
        show={showModal}
        title="Withdraw"
        mixNum={mixAmount}
        ton={Number(userStore.ton)}
        onClick={handleModalClick}
        pernum={percentage}
      />
      <main className="w-full h-full p-3">
        <div className="text-3xl text-white font-semibold">Account</div>
        <div className="w-full h-80 bg-[#282b47] mt-3 rounded-3xl">
          <div className="flex h-[300px] flex-col items-center justify-between">
            <div className="w-24 h-24 rounded-full overflow-hidden ml-1 bg-[#778eb3] flex items-center justify-center flex-shrink-0 mt-3">
              <div>
                <img src={avatarPath} alt="" />
              </div>
            </div>
            <div className="text-xl text-white font-semibold">
              <p>UserID: {userInfo.uid}</p>
            </div>
            <div className="min-w-0 h-9 border border-[#5e6074] text-white rounded-3xl inline-flex items-center justify-center p-2">
              <img src={planeicon} alt="" className="w-10 h-10" />
              <p className="truncate text-center">{userInfo.first_name}</p>
            </div>
            <div className="flex space-x-2">
              <div className="w-[151px] h-[64px] bg-[#353852] flex items-center justify-around rounded-3xl">
                <div>
                  <img src={start} alt="" className="w-8 h-8" />
                </div>
                <div className="text-white text-xl">{userStore.point}</div>
              </div>
              <div className="w-[151px] h-[64px] bg-[#353852] flex items-center justify-around rounded-3xl">
                <div>
                  <img src={ton} alt="" className="w-8 h-8" />
                </div>
                <div className="text-white text-xl">{userStore.ton}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-16 bg-[#1d1e38] mt-4 rounded-full flex items-center justify-around">
          <div className="flex items-center">
            <div className="w-10 h-10 bg-[#13142e] rounded-full flex justify-center items-center">
              <img src={card} className="w-7 h-7" alt="" />
            </div>
            <div className="text-sm text-white ml-3">
              <p>
                {walletAddress ? shortenedString(walletAddress) : 'Unbound'}
              </p>
            </div>
          </div>
          <div>
            {wallet ? (
              <div
                className={`w-full h-8 bg-white rounded-full text-sm flex items-center p-3  ${WithIsLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                onClick={!WithIsLoading ? handleWithdrawClick : null}
              >
                {WithIsLoading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 20, color: 'black' }}
                        spin
                      />
                    }
                  />
                ) : (
                  'Withdraw'
                )}
              </div>
            ) : (
              <div
                onClick={open}
                className="w-full h-8 bg-white rounded-full text-sm flex items-center p-3"
              >
                Connect Wallet
              </div>
            )}
          </div>
        </div>
        <div
          className="w-full h-28 mt-3 bg-cover bg-center flex items-center justify-center text-white rounded-3xl"
          style={{ backgroundImage: `url(${docbg})` }}
        ></div>

        <Drawer
          placement="bottom"
          onClose={() => toggleDrawer(1, false)}
          height={DrawerHeight}
          open={isDrawerOpen[1]}
        >
          <div className="p-3">
            <div className="w-full h-10">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-xl text-white">Withdraw</p>
                </div>
                <div
                  className="flex items-center"
                  onClick={() => toggleDrawer(1, false)}
                >
                  <img src={close} className="w-8 h-8" alt="" />
                </div>
              </div>
            </div>
            <div className="w-full h-11 mt-1">
              <Input
                placeholder="Enter the amount you want to withdraw"
                value={withdrawValue}
                className="h-full bg-[#3d3e59] custom-input"
                onChange={handleWithdrawValueChange}
                onFocus={handleInputFocus}
              />
            </div>
            {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
            <div className="w-full mt-2 flex justify-between text-xs">
              <div>
                <p className="text-white flex">
                  Withdrawable amount:{' '}
                  <img src={ton} className="h-4 ml-2" alt="TON" />{' '}
                  <span className="ml-1"> {userStore.ton}</span>
                </p>
                <p className="text-white flex mt-1">
                  handling fee: <img src={ton} className="h-4 ml-2" alt="TON" />{' '}
                  <span className="ml-1">{serviceCharge}</span>{' '}
                </p>
              </div>
              <p className="text-[#50a0fc]" onClick={withdrawAll}>
                Withdraw all
              </p>
            </div>
            <div
              className="w-full h-11 bg-[#2631fb] mt-10 mb-10 rounded-full flex items-center justify-center text-white text-base"
              onClick={handleNextStep}
            >
              Confirm
            </div>
          </div>
        </Drawer>

        <Drawer
          placement="bottom"
          onClose={() => toggleLastDrawer(1, false)}
          height="auto"
          open={isLastDrawerOpen[1]}
        >
          <div className="p-3">
            <div className="w-full h-10">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-xl text-white">Withdraw</p>
                </div>
                <div
                  className="flex items-center"
                  onClick={() => toggleLastDrawer(1, false)}
                >
                  <img src={close} className="w-8 h-8" alt="" />
                </div>
              </div>
            </div>
            <div className="w-full h-11 text-white flex items-center">
              <p className="text-base">$ {withdrawValue} TON</p>
              <img src={wton} className="w-7 h-7 ml-2" alt="" />
            </div>
            <div className="w-full mt-1 text-white">
              <p className="text-xs text-[red]">
                The withdrawal will be credited approximately fifteen minutes
                after it is successfully completed.
              </p>
              <p className="text-base mt-2">From</p>
              <div className="w-full  rounded-2xl bg-[#3f3e52] text-sm text-[#8c8b8e] flex flex-col justify-center p-4 mt-2">
                <p>BitPlay account:</p>
                <p>{userInfo.first_name}</p>
              </div>
              <p className="text-base">To</p>
              <div className="w-full  rounded-2xl bg-[#3f3e52] text-sm text-[#8c8b8e] flex flex-col justify-center p-4 mt-2">
                <p>Wallet:</p>
                <p
                  className="w-full leading-7"
                  style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
                >
                  {walletAddress}
                </p>
              </div>
            </div>
            <div
              className={`w-full h-11 bg-[#2631fb] mt-10 mb-10 rounded-full flex items-center justify-center text-white text-base ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              onClick={!isLoading ? handleWithdraw : null}
            >
              {isLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 20, color: 'black' }}
                      spin
                    />
                  }
                />
              ) : (
                'Confirm'
              )}
            </div>
          </div>
        </Drawer>
      </main>
    </div>
  );
}
