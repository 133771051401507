// src/api/endpoints.js
const endpoints = {
  getCreateUser: '/v1/user/create',
  getUserInfo: '/v1/user/info',
  taskList: '/v1/task/list',
  getGameList: '/v1/game/list',
  searchGameList: '/v1/game/query',
  createTask: '/v1/event/create',
  eventTask: '/v1/event/list',
  eventConfirm: '/v1/event/confirm',
  getInvite: '/v1/invite/state',
  getInviteList: '/v1/invite/list',
  getWheelList: '/v1/wheel/list',
  postCreateSpin: '/v1/spin/create',
  postConfirmSpin: '/v1/spin/confirm',
  getCurrencyList: '/v1/currency/list',
  getSettingList: '/v1/setting/list',
  postAddress: '/v1/user/address',
  postTonCreate: '/v1/cashout/create',
  getTonlist: '/v1/cashout/list',
};

export default endpoints;
