import PropTypes from 'prop-types';
import './CustomProgress.css';
import wton from '@/assets/ton.png';

// eslint-disable-next-line react/prop-types
const CustomProgress = ({ percent, ton, mixNum }) => {
  return (
    <div className="relative w-full h-5 mt-8">
      <div className="w-full h-2.5 rounded-full bg-[#3c3b50]">
        <div
          className="relative h-2.5 custom-progress-bar rounded-full"
          style={{ width: `${percent}%` }}
        >
          <div
            className="absolute top-[-10vw] transform -translate-x-1/2 text-sm rounded-lg custom-progress-tooltip flex items-center justify-center"
            style={{ left: '100%' }}
          >
            <img src={wton} className="h-4 mr-2" alt="TON" />
            <p>{ton}</p>
            <div className="custom-tooltip-arrow"></div>
          </div>
        </div>
      </div>

      <div className="flex justify-between text-xs text-white mt-1">
        <span>0</span>
        <span>{mixNum}</span>
      </div>
    </div>
  );
};

CustomProgress.propTypes = {
  percent: PropTypes.number.isRequired,
  ton: PropTypes.number.isRequired,
};

export default CustomProgress;
