import { useState, useEffect } from 'react';
import { createTask, eventTask } from '@/api/apiMethods.js';
import { toClaimFn } from '@/utils/toClaimStore';
import { getTaskList } from '@/utils/hooks/useTaskList.js';
import WebApp from '@twa-dev/sdk';
import go from '@/assets/taskgo.png';
import collect from '@/assets/collect.png';
import iconStore from '@/utils/hooks/iconStore';
import userStore from '@/utils/hooks/userStore';
import { getShareLink } from '@/utils/getShareLink';
import './css/task.css';

// eslint-disable-next-line react/prop-types
export default function Tasks({ onTaskStatusChange }) {
  const [TaskItems, setTaskItems] = useState([]);
  const [taskListData, setTaskListData] = useState([]);
  const [comTaskListData, setComTaskListData] = useState([]);
  const [waitGetAwardData, setWaitGetAwardData] = useState([]);
  const [isClaiming, setIsClaiming] = useState(false); // Add state to track if claiming is in progress

  let cachedTaskItems = null;

  const fetchAndSetTaskItems = async () => {
    try {
      const { unfinishedItems, finishedItems, waitGetAwardItem } =
        await getTaskList();
      setComTaskListData(finishedItems);
      setWaitGetAwardData(waitGetAwardItem);
      const filteredItems = unfinishedItems.filter((item) => !item.ready);
      const groupedData = Object.values(
        filteredItems.reduce((acc, item) => {
          const classify = item.classify;
          if (!acc[classify]) {
            acc[classify] = { title: classify, items: [] };
          }
          acc[classify].items.push(item);
          return acc;
        }, {}),
      );
      cachedTaskItems = groupedData;
      setTaskItems(groupedData);
    } catch (error) {
      console.error('Error fetching task items:', error);
    }
  };

  useEffect(() => {
    fetchAndSetTaskItems();
  }, []);

  const updateTaskData = (item, res) => {
    const index = taskListData.findIndex((j) => j.id == res.event.task_id);
    if (index !== -1) {
      const newTaskListData = [...taskListData];
      newTaskListData[index] = { ...item, ...res.event };
      setTaskListData(newTaskListData);
    }
  };

  const TaskItemClick = async (item) => {
    try {
      const res = await createTask({ task_id: item.id });
      const updatedItem = { ...item, ...res.event_info };
      updateTaskData(item, updatedItem);

      if (!cachedTaskItems) {
        await fetchAndSetTaskItems();
      }

      cachedTaskItems = cachedTaskItems.map((task) =>
        task.id === updatedItem.id ? updatedItem : task,
      );
      setTaskItems(cachedTaskItems);

      if (item.classify == 'system_invite') {
        getShareLink('system_invite', 'user_action', item.url);
      } else {
        if (item.url.includes('t.me')) {
          WebApp.openTelegramLink(item.url);
        } else {
          WebApp.openLink(item.url, { try_instant_view: true });
        }
      }
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  const handleTaskComplete = (id) => {
    if (onTaskStatusChange) {
      onTaskStatusChange(id);
    }
  };

  const claimRewards = async (item, status) => {
    if (isClaiming) {
      console.log("Claiming already in progress...");
      return; // Prevent multiple claims while a claim is already in progress
    }

    setIsClaiming(true); // Set the claiming state to true to indicate claiming is in progress

    try {
      const arr = await eventTask();
      const matchedData = arr.filter(
        (task) => task.task_id === item.id && task.confirm === false,
      );
      console.log(matchedData, 'Matched Data');

      await toClaimFn(matchedData, status, taskListData, 'task');

      for (const prize of item.tasks_prize) {
        if (prize.currency_type === 'point') {
          handleTaskComplete('point');
          await new Promise((resolve) => setTimeout(resolve, 1200));
          userStore.addPoint(prize.reward_value);
        } else if (prize.currency_type === 'credit') {
          handleTaskComplete('credit');
          await new Promise((resolve) => setTimeout(resolve, 1200));
          userStore.addCredit(prize.reward_value);
        } else {
          handleTaskComplete('ton');
          await new Promise((resolve) => setTimeout(resolve, 1200));
          userStore.addTon(prize.reward_value);
        }
      }
      await fetchAndSetTaskItems();
    } catch (error) {
      console.error('Error claiming rewards:', error);
    } finally {
      setIsClaiming(false); // Set the claiming state back to false when the task is complete
    }
  };

  const renderTaskPrize = (prize, index) => {
    const currency = iconStore.currencyList?.find(
      (currency) => currency.key === prize.currency_type,
    );
    const iconUrl = currency ? `${currency.icon}` : '';

    return (
      <div key={index} className="flex items-center text-white ml-2">
        <img src={iconUrl} className="w-[24px] h-[24px]" alt="" />
        <p>+{prize.reward_value}</p>
      </div>
    );
  };

  const renderTaskItem = (item) => (
    <div className="w-full h-full mt-2" key={item.id}>
      <div
        className="w-80 h-full rounded-lg flex flex-col p-2 mb-2 task-body"
        onClick={() => TaskItemClick(item, true)}
      >
        <div className="flex-grow">
          <p className="text-base">{item.title}</p>
          <div className="text-sm font-semibold h-8 text-[#d5c7fd] rounded inline-flex items-center px-2 desc-cor">
            <span className="mr-2">{item.description}</span>
          </div>
        </div>
        <div className="flex items-center justify-between text-xs">
          <div className="w-44 flex justify-between">
            {item.tasks_prize.map((taskPrize, index) => (
              <div
                key={index}
                className="h-6 bg-[#5d37a9] border border-[#422d9e] rounded-full flex items-center justify-center p-2"
              >
                {renderTaskPrize(taskPrize)}
              </div>
            ))}
          </div>
          <img
            src={go}
            alt="icon"
            className="w-[80px] h-[24px] ml-5"
            style={{ objectFit: 'contain' }}
          />
        </div>
      </div>
    </div>
  );

  const renderWaitItem = (item) => (
    <div className="w-full mt-2" key={item.id}>
      <div
        className="w-80 h-full rounded-lg flex flex-col p-2 mb-2 task-collbody"
        onClick={() => claimRewards(item)}
      >
        <div className="flex-grow">
          <p className="text-base">{item.title}</p>
          <div className="text-sm font-semibold h-8 text-[#d5c7fd] rounded inline-flex items-center px-2 desc-cor">
            <span className="mr-2">{item.description}</span>
          </div>
        </div>
        <div className="flex items-center justify-between text-xs">
          <div className="w-44 flex justify-between">
            {item.tasks_prize.map((taskPrize, index) => (
              <div
                key={index}
                className="h-6 bg-[#5d37a9] border border-[#422d9e] rounded-full flex items-center justify-center p-2"
              >
                {renderTaskPrize(taskPrize)}
              </div>
            ))}
          </div>
          <img
            src={collect}
            alt="icon"
            className="w-[80px] h-[24px] ml-5"
            style={{ objectFit: 'contain' }}
          />
        </div>
      </div>
    </div>
  );

  const renderCompletedTaskItem = (item) => (
    <div className="w-full mt-2" key={item.id}>
      <div className="w-80 h-full rounded-lg flex flex-col p-2 mb-2 comtask-body">
        <div className="flex-grow">
          <p className="text-base">{item.title}</p>
          <div className="text-sm font-semibold h-8 text-[#d5c7fd] rounded inline-flex items-center px-2 desc-cor">
            <span className="mr-2">{item.description}</span>
          </div>
        </div>
        <div className="flex items-center justify-between text-xs">
          <div className="w-44 flex justify-between">
            {item.tasks_prize.map((taskPrize, index) => (
              <div
                key={index}
                className="h-6 bg-[#5d37a9] border border-[#422d9e] rounded-full flex items-center justify-center p-2"
              >
                {renderTaskPrize(taskPrize)}
              </div>
            ))}
          </div>
          <img
            src={collect}
            alt="icon"
            className="w-[80px] h-[24px] ml-5"
            style={{ objectFit: 'contain' }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div>
        <main className="w-full p-1">
          <div className="flex flex-col items-center">
            <div>
              {waitGetAwardData.length > 0 ? (
                waitGetAwardData.map(renderWaitItem)
              ) : (
                <div></div>
              )}
              {TaskItems.length > 0 ? (
                TaskItems.map((category, index) => (
                  <div key={index}>{category.items.map(renderTaskItem)}</div>
                ))
              ) : (
                <div></div>
              )}
              {comTaskListData.length > 0 ? (
                comTaskListData.map(renderCompletedTaskItem)
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
