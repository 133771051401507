import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import userStore from '@/utils/hooks/userStore';
import iconStore from '@/utils/hooks/iconStore';

const BalanceItem = ({ icon, value, id, onClick, isHit }) => (
  <motion.div
    id={id}
    className="bg-[#5123a9] flex-grow text-center border border-[#a882fa] rounded flex items-center justify-around flex-wrap p-[0.2rem]"
    onClick={onClick}
    animate={{
      scale: isHit ? [1, 1.2, 1] : 1,
    }}
    transition={{
      duration: 0.3,
      ease: 'easeInOut',
    }}
    style={{ zIndex: 9999 }}
    key={id}
  >
    <div className="flex-shrink-0">
      <img src={icon} className="w-7 h-7" alt="" />
    </div>
    <div className="bg-[#200842] h-[20px] px-1 border border-[#eedffe] rounded text-xs text-white ml-1 flex items-center" >
      {value}
    </div>
  </motion.div>
);

BalanceItem.propTypes = {
  icon: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isHit: PropTypes.bool,
};

// eslint-disable-next-line react/display-name
const Balance = forwardRef(({ showTicket }, ref) => {
  const [currencyList, setCurrencyList] = useState([]);
  const [pointIconUrl, setPointIconUrl] = useState('');
  const [creditIconUrl, setCreditIconUrl] = useState('');
  const [tonIconUrl, setTonIconUrl] = useState('');
  const [animateTarget, setAnimateTarget] = useState(null);
  const [showIcon, setShowIcon] = useState(true);
  const [hitBalanceItem, setHitBalanceItem] = useState(null);
  const [showMask, setShowMask] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  useEffect(() => {
    async function fetchCurrencyList() {
      try {
        await iconStore.getCurrencyList();
        setCurrencyList(iconStore.currencyList);
      } catch (error) {
        console.error('Failed to fetch currency list:', error);
      }
    }
    fetchCurrencyList();
  }, []);

  useEffect(() => {
    if (currencyList && currencyList.length > 0) {
      setPointIconUrl(iconStore.pointIconUrl);
      setTonIconUrl(iconStore.tonIconUrl);
      setCreditIconUrl(iconStore.creditIconUrl);
    }
  }, [currencyList]);

  const handleAnimate = (id) => {
    setAnimateTarget(null);
    setShowIcon(false);
    setShowMask(true);
    setHitBalanceItem(null);
    setIsAnimating(false);

    setTimeout(() => {
      setAnimateTarget(id);
      setShowIcon(true);
    }, 100);
  };

  const getTargetPosition = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      const targetRect = targetElement.getBoundingClientRect();
      return {
        x: targetRect.left + targetRect.width / 2 - window.innerWidth / 2,
        y: targetRect.top + targetRect.height / 2 - window.innerHeight / 2,
      };
    }
    return { x: 0, y: 0 };
  };

  const handleAnimationComplete = () => {
    setHitBalanceItem(animateTarget);
    setShowMask(false);
    setIsAnimating(true);
  };

  useImperativeHandle(ref, () => ({
    handleAnimate,
  }));

  return (
    <div className="text-xl w-full font-semibold flex justify-center">
      {/* 遮罩层 */}
      {showMask && (
        <motion.div
          className="fixed top-0 left-0 w-full h-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          style={{ zIndex: 9998 }}
        />
      )}

      <div className="h-full flex justify-between space-x-4">
        {showTicket && creditIconUrl && (
          <BalanceItem
            icon={creditIconUrl}
            value={userStore.credit}
            id="credit"
            isHit={hitBalanceItem === 'credit' && isAnimating}
          />
        )}
        {pointIconUrl && (
          <BalanceItem
            icon={pointIconUrl}
            value={userStore.point}
            id="point"
            isHit={hitBalanceItem === 'point' && isAnimating}
          />
        )}
        {tonIconUrl && (
          <BalanceItem
            icon={tonIconUrl}
            value={userStore.ton}
            id="ton"
            isHit={hitBalanceItem === 'ton' && isAnimating}
          />
        )}
      </div>

      {animateTarget && showIcon && (
        <motion.img
          key={animateTarget}
          src={
            animateTarget === 'credit'
              ? creditIconUrl
              : animateTarget === 'point'
                ? pointIconUrl
                : tonIconUrl
          }
          alt="Flying Icon"
          // initial={{ x: 0, y: 0, scale: 1 }}
          animate={{
            x: getTargetPosition(animateTarget).x,
            y: getTargetPosition(animateTarget).y,
            opacity: [1, 1, 1, 0],
          }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          onAnimationComplete={handleAnimationComplete}
          className="fixed"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10000,
          }}
        />
      )}
    </div>
  );
});

Balance.propTypes = {
  showTicket: PropTypes.bool.isRequired,
};

export default Balance;
