import WebApp from '@twa-dev/sdk';

export const getShareLink = async (name, category, copyurl) => {
  try {
    const url =
      'https://t.me/share/url?url=' +
      encodeURI(copyurl) +
      `&text=🎉 Join me on BitPlay for tons of awesome Web3 games and get free TON! Let’s have some fun together!`;
    WebApp.openTelegramLink(url);
  } catch (error) {
    console.log(error);
  }
};
