import { useState, useRef, useEffect, useCallback } from 'react';
import { LuckyWheel } from '@lucky-canvas/react';
import {
  getWheelList,
  postCreateSpin,
  postConfirmSpin,
} from '@/api/apiMethods.js';
import point from '@/assets/point.png';
// import WinningAnimation from './components/WinningAnimation';
import Balance from '@/components/Balance';
import Task from './components/task';
import TaskCountDown from './components/TaskCountDown';
import spinBody from '@/assets/spinBody.png';
import spinpng from '@/assets/spinbutton.png';
import logogf from '@/assets/logogf.png';
import Mask from '@/assets/Mask.png';
import userStore from '@/utils/hooks/userStore';
import usePrizeTransformData from './hooks/usePrizeTransformData';
import Modal from '@/components/Modal/Modal';
import iconStore from '@/utils/hooks/iconStore';
import spinborder from '@/assets/spinborder.gif';
import logolucky from '@/assets/logo_lucky.gif';
import logo from '@/assets/logo.png';
import './page.css';
import { Affix } from 'antd';

export default function Lucky() {
  const [rawData, setRawData] = useState(null);
  const [prize, setPrize] = useState(null);
  const [creditIconUrl, setCreditIconUrl] = useState('');
  const [isSpinning, setIsSpinning] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalValue, setModalValue] = useState('');
  const [modalType, setModalType] = useState('');
  const [btnName, setBtnName] = useState('');
  const [prizeIcon, setPrizeIcon] = useState('');
  const [currencyId, setCurrencyId] = useState('');
  const myLucky = useRef();
  const balanceRef = useRef();

  const blocks = useRef([
    {
      imgs: [
        {
          src: Mask,
          width: '100%',
          rotate: true,
        },
      ],
    },
  ]).current;

  const defaultConfig = useRef({ decelerationTime: '1500' }).current;

  const buttons = useRef([
    {
      radius: '40%',
      imgs: [
        {
          src: point,
          width: '80%',
          top: '-100%',
          left: '0%',
        },
      ],
    },
  ]).current;

  const triggerAnimation = (id) => {
    if (balanceRef.current) {
      balanceRef.current.handleAnimate(id);
    }
  };
  const handleStart = useCallback(async () => {
    if (isSpinning) return;

    if (userStore.credit < rawData.wheels[0].cost_value) {
      // userStore.credit < rawData.wheels[0].cost_value
      setModalContent(`Insufficient credit, please complete the task`);
      setBtnName('Close');
      setShowModal(true);
      setModalType('NoCredit');
      setPrizeIcon(null);
    } else {
      setIsSpinning(true);
      userStore.subtractCredit(rawData.wheels[0].cost_value);
      if (myLucky.current) {
        myLucky.current.play();
        try {
          const res = await postCreateSpin({ wid: rawData.wheels[0].id });
          await postConfirmSpin({ spin_id: res.spin.id });
          const index = prize.findIndex((item) => item.id == res.spin.prize_id);
          setTimeout(() => {
            if (myLucky.current) {
              myLucky.current.stop(index);
            }
          }, 1500);
        } catch (error) {
          console.error('Failed to create spin:', error);
          setIsSpinning(false);
        }
      }
    }
  }, [isSpinning, rawData, prize]);

  const handleEnd = useCallback((prize) => {
    console.log(prize, '121312312313123');
    if (prize) {
      setCurrencyId(prize.currency_id);
      const matchedCurrency = iconStore.currencyList.find(
        (currency) => currency.id == prize.currency_id,
      );
      if (prize?.fonts?.length > 0) {
        setModalContent(``);
        setModalValue(`${prize.fonts[0].text}`);
        setBtnName('');
        setModalType('success');
        setShowModal(true);
      } else {
        console.error('prize.fonts is undefined or empty');
      }
      if (matchedCurrency) {
        setPrizeIcon(`${matchedCurrency.icon}`);
        const value = parseFloat(prize.fonts[0].text, 10);
        if (!isNaN(value)) {
          if (matchedCurrency.key === 'point') {
            triggerAnimation('point');
            setTimeout(() => {
              userStore.addPoint(value);
            }, 1000);
          } else if (matchedCurrency.key === 'credit') {
            triggerAnimation('credit');
            setTimeout(() => {
              userStore.addCredit(value);
            }, 1000);
          } else if (matchedCurrency.key === 'ton') {
            triggerAnimation('ton');
            setTimeout(() => {
              console.log(value, 'valuevaluevalue');
              userStore.addTon(value);
            }, 1000);
          }
        } else {
          console.error('Invalid value:', prize.fonts[0].text);
        }
      }
    }
  }, []);

  const modalClick = useCallback(async () => {
    if (modalType === 'success') {
      try {
        // setShowAnimation(true);
        setShowModal(false);
        setIsSpinning(false);
        // const matchedCurrency = iconStore.currencyList.find(
        //   (currency) => currency.id == currencyId,
        // );
        // if (matchedCurrency) {
        //   const value = parseInt(modalValue, 10);
        //   if (!isNaN(value)) {
        //     if (matchedCurrency.key === 'point') {
        //       userStore.addPoint(value);
        //     } else if (matchedCurrency.key === 'credit') {
        //       userStore.addCredit(value);
        //     } else if (matchedCurrency.key === 'ton') {
        //       userStore.addTon(value);
        //     }
        //   } else {
        //     console.error('Invalid value:', modalValue);
        //   }
        // }
      } catch (error) {
        console.error('Failed to confirm spin:', error);
      }
    } else {
      setShowModal(false);
      setIsSpinning(false);
    }
  }, [modalType, currencyId, modalValue]);

  // const handleAnimationEnd = () => {
  //   setShowAnimation(false);
  //   console.log('Animation ended');
  // };
  useEffect(() => {
    async function fetchData() {
      try {
        const wheelList = await getWheelList();
        setRawData(wheelList);
      } catch (error) {
        console.error('Failed to fetch wheel list:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (iconStore.currencyList && iconStore.currencyList.length > 0) {
      setCreditIconUrl(iconStore.creditIconUrl);
    }
  }, [iconStore.currencyList]);

  const transformedData = usePrizeTransformData(rawData);

  useEffect(() => {
    if (
      transformedData &&
      transformedData.length > 0 &&
      transformedData[0].prizes
    ) {
      setPrize(transformedData[0].prizes);
    }
  }, [transformedData]);

  // const animationPath = [
  //   { x: 0, y: 0 },
  //   { x: 100, y: -50 },
  //   { x: 200, y: -100 },
  // ];

  // const targetPosition = { x: 300, y: -200 };

  return (
    <div className="overflow-hidden">
      <Modal
        show={showModal}
        content={modalContent}
        onClick={modalClick}
        btnName={btnName}
        value={Number(modalValue)}
        prizeIcon={prizeIcon}
      />
      <Affix offsetTop={0}>
        <div className="mt-2 z-50">
          <Balance showTicket={true} ref={balanceRef} />
        </div>
      </Affix>
      <div className="w-[100vw] relative mt-2">
        <img src={spinBody} width="100%" />
        <div
          style={{
            position: 'absolute',
            top: '31vw',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 9,
          }}
        >
          <LuckyWheel
            width="57.7vw"
            height="57.7vw"
            ref={myLucky}
            blocks={blocks}
            prizes={prize}
            buttons={buttons}
            defaultConfig={defaultConfig}
            onEnd={handleEnd}
          />
          <div
            className={`w-full absolute top-[79vw] left-0 transition transform duration-150 ${isSpinning ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer active:opacity-75 active:scale-95'}`}
            onClick={handleStart}
          >
            <img src={spinpng} alt="" />
            <div className="absolute top-[0.4vw] left-[30vw] flex items-center">
              <p
                className="text-white text-sm ml-1 flex items-center"
                style={{ textShadow: '0px 3px 5px #333333' }}
              >
                (
                <img src={creditIconUrl} width="30" className="inline-block" />
                {transformedData?.[0]?.cost_value})
              </p>
            </div>
          </div>
          <div className={`absolute top-[-23.5vw] left-[16%] z-[9]`}>
            <img src={logogf} alt="" className="w-[10vw] h-[10vw] animate" />
          </div>
          <div className={`absolute top-[-23.2vw] left-[30%] `}>
            <img src={logo} alt="" className="w-[34vw] h-[10vw]" />
          </div>
          <div className="absolute bottom-[-52vw] left-0 w-full  ">
            <TaskCountDown />
          </div>
        </div>
        <div className={`absolute top-[14.4vw] left-[4.5%] `}>
          <img src={spinborder} alt="" className="w-[91vw] h-[91vw]" />
        </div>
        <div className={`absolute top-[-33vw] left-[4.5%] `}>
          <img src={logolucky} alt="" className="w-[91vw] h-[91vw]" />
        </div>
      </div>
      <div className="w-full mt-1 flex justify-center  p-2">
        <div className="bg-gradient-to-b from-[#592FBC] to-[#8263B9] rounded-lg w-full">
          <Task onTaskStatusChange={triggerAnimation} />
        </div>
      </div>
    </div>
  );
}
