import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // 引入 React Router
import HomePage from './pages/index';
import AiPage from './pages/aipage/page';
import GamePage from './pages/games/page';
import LuckyPage from './pages/lucky/page';
import AccountPage from './pages/account/page';
import TabBar from './components/TabBar/TabBar';
import { Provider } from 'mobx-react';
import userStore from '@/utils/hooks/userStore';
import ErrorBoundary from './components/ErrorBoundary';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import loadingStore from '@/utils/hooks/loadingStore';
import { observer } from 'mobx-react-lite';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import GlobalLoading from './components/GloLoading/Loading';

const stores = {
  userStore,
  loadingStore,
};

const App = observer(() => {
  const [currentPage, setCurrentPage] = useState('home');
  const [showTabbar, setShowTabbar] = useState(true);
  const [showSplash, setShowSplash] = useState(true);
  const [isReady, setIsReady] = useState(false);
  let pagePaddingBottom = 'pb-20';

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const statusParam = queryParams.get('status');

  useEffect(() => {
    setShowSplash(true);

    const timer = setTimeout(() => {
      setIsReady(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (location.pathname === '/spin/') {
        await userStore.fetchUserInfo();
        setCurrentPage('lucky');
      } else if (location.pathname === '/games/') {
        await userStore.fetchUserInfo();
        setCurrentPage('games');
      } else {
        setCurrentPage('home');
      }
    };

    fetchData();
  }, [location, statusParam]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  let currentComponent = null;
  switch (currentPage) {
    case 'home':
      currentComponent = <HomePage handlePageChange={handlePageChange} />;
      break;
    case 'ai':
      currentComponent = <AiPage />;
      pagePaddingBottom = 'pb-0';
      break;
    case 'games':
      currentComponent = <GamePage setShowTabbar={setShowTabbar} />;
      break;
    case 'lucky':
      currentComponent = <LuckyPage />;
      pagePaddingBottom = 'pb-20';
      break;
    case 'account':
      currentComponent = <AccountPage />;
      break;
    default:
      currentComponent = <div>Page Not Found</div>;
  }

  if (!isReady) {
    return null;
  }

  const content = (
    <Provider {...stores}>
      <ErrorBoundary>
        <div className="relative min-h-screen">
          {showSplash && <GlobalLoading setShowSplash={setShowSplash} />}
          {loadingStore.loading && (
            <div className="fixed inset-0 flex items-center justify-center z-[99] bg-[#13152d] w-75 h-75 ">
              <Spin
                spinning={loadingStore.loading}
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
              />
            </div>
          )}
          <div className={showTabbar ? pagePaddingBottom : 'pb-0'}>
            {currentComponent}
          </div>
          {/* TabBar */}
          {showTabbar && (
            <TabBar
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
      </ErrorBoundary>
    </Provider>
  );

  return currentPage === 'account' ? (
    <TonConnectUIProvider manifestUrl={import.meta.env.VITE_API_LINK}>
      {content}
    </TonConnectUIProvider>
  ) : (
    content
  );
});

export default App;
