import { useState, useEffect } from 'react';
import CountdownImg from '@/assets/Countdown.png';
import './CountDown.css';
import PropTypes from 'prop-types';

const CustomCountdown = ({ deadline, onFinish }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(deadline) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      onFinish();
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [deadline]);

  return (
    <div
      className="flex justify-center ml-1 w-[70px] p-1"
      style={{
        backgroundImage: `url(${CountdownImg})`,
        backgroundPosition: 'center',
        backgroundSize: '100% 100%',
      }}
    >
      <div className="flex mb-1">
        <div className="text-xs data-bor">
          {String(timeLeft.hours).padStart(2, '0')}
        </div>
        <div className="text-xs">:</div>
        <div className="text-xs">
          {String(timeLeft.minutes).padStart(2, '0')}
        </div>
        <div className="text-xs">:</div>
        <div className="text-xs">
          {String(timeLeft.seconds).padStart(2, '0')}
        </div>
      </div>
    </div>
  );
};

CustomCountdown.propTypes = {
  deadline: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default CustomCountdown;
